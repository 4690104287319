<template>
  <div>
    <v-container fluid>
      <v-card class="transparent" flat>
        <v-card-text class="text-center">

          <img :src="require('@/assets/401.svg')"
               :width="$vuetify.breakpoint.mobile ? '200' : '400'"
               alt="">

          <h1 :class="!$vuetify.breakpoint.mobile || 'fs-22'" class="my-4">
            401 Non autorisé !
          </h1>

          <p>
            Désolé, vous n'êtes pas autorisé à accéder à cette page.
          </p>

          <v-btn color="primary" depressed to="/">Accueil</v-btn>

        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>


export default {}
</script>

<style scoped>

</style>